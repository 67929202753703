import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import { userInfoApi } from "@/api/user";
import { pcAdApi, pcConfigApi } from "@/api/home";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    userInfo: {},
    config: {},
  },
  getters: {
    token: (state) => state.token,
    userInfo: (state) => state.userInfo,
    config: (state) => state.config,
  },
  mutations: {
    SETTOKEN(state, token) {
      state.token = token;
    },
    SETUSERINFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    WEBCONFIG(state, info) {
      state.config = info;
    },
  },
  actions: {
    setToken: async ({ commit }, value) => {
      commit("SETTOKEN", value);
      localStorage.setItem("mall_token", value);
    },
    setUserInfo: async ({ commit }, value) => {
      commit("SETUSERINFO", value);
      localStorage.setItem("mall_userInfo", JSON.stringify(value));
    },

    //获取用户信息
    getUserInfo: async ({ commit }, value) => {
      return new Promise((resolve, reject) => {
        userInfoApi()
          .then((res) => {
            commit("SETUSERINFO", res.data);
            localStorage.setItem("mall_userInfo", JSON.stringify(res.data));
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    //获取首页广告配置
    getPcConfigApi: async ({ commit }, value) => {
      return new Promise((resolve, reject) => {
        pcConfigApi().then((res) => {
          commit("WEBCONFIG", JSON.parse(res.data || "{}"));
          resolve(JSON.parse(res.data || "{}"));
        });
      });
    },

    //退出登录
    logOut: ({ commit }, value) => {
      commit("SETTOKEN", "");
      commit("SETUSERINFO", {});
      localStorage.removeItem("mall_token");
      localStorage.removeItem("mall_userInfo");
      router.push("/");
    },
  },
  modules: {},
});
